
import {
    getMaterialTree
} from '@/api/msg'
import _ from 'lodash'
import dataTypeItem from './data-type-item'

import './data-type.less'

export default {
    name: 'DataType',
    components: {
        dataTypeItem
    },
    provide () {
        return {
            handleNavClick: this.handleNavClick,
            navActiveIdList: () => this.navActiveIdList
        }
    },
    props: {
        value: {
            type: [String, Number],
            default: 'all'
        }
    },
    data () {
        return {
            navTree: [],
            visible: false,
            navActive: [],
            children: [],
            hoverActive: null
        }
    },
    computed: {
        navList () {
            const navList = []
            if (this.navTree.length) {
                const flatTree = (list, tree) => {
                    if (Array.isArray(tree) && tree.length) {
                        list.push(...tree)
                        tree.forEach(item => {
                            flatTree(list, item.list)
                        })
                    }
                }
                flatTree(navList, this.navTree)
            }
            return navList
        },
        navActiveId () {
            const navList = this.navList
            const value = this.value
            const findTopId = (currentId) => {
                const activeData = navList.find(item => item.id === currentId)
                if (activeData.parentId !== '0') {
                    return findTopId(activeData.parentId)
                }
                return activeData.id
            }
            return findTopId(value)
        },
        navActiveIdList () {
            const value = this.value
            return this.handleFindTopData(value).map(item => item.id)
        },
        wrapperStyle () {
            return {
                height: this.visible ? '380px' : '0px'
            }
        }
    },
    created () {
        this.$init()
    },
    methods: {
        $init () {
            getMaterialTree().then(res => {
                this.navTree = [
                    {
                        name: '全部',
                        id: 'all',
                        parentId: '0'
                    },
                    ...res
                ]
            })
        },
        handleFindTopData (currentId, list = []) {
            const activeData = this.navList.find(item => item.id === currentId)
            if (activeData.parentId !== '0') {
                return this.handleFindTopData(activeData.parentId, [activeData, ...list])
            } else {
                return [activeData, ...list]
            }
        },
        handleNavClick (navItem) {
            const { id } = navItem
            this.$emit('input', id)
            const activeList = this.handleFindTopData(id)
            this.$emit('change', activeList)
            if (!this.navTree.some(item => item.id === id)) {
                this.$refs.dataTypeWrapper.style.display = 'none'
            }
        },
        handleNavEnter (navItem) {
            this.$refs.dataTypeItem.hide()
            if (this.navActiveIdList.includes(navItem.id)) {
                this.$refs.dataTypeItem.handleEcho()
            }
            if (this.hoverActive !== navItem.id) {
                this.$refs.dataTypeItem.handleScrollTop()
            }
            this.children = navItem.list || []
            this.hoverActive = navItem.id
        },
        handleNavLeave () {
            this.visible = false
            this.hoverActive = null
        },
        handleMove (e) {
            const className = e.target.className
            if (typeof className === 'object' || className.includes('data-type-content')) return
            if (!className.includes('data-type-item-all')) {
                this.$refs.dataTypeWrapper.style.display = 'block'
                this.visible = true
            } else {
                this.hoverActive = null
                this.visible = false
            }
        },
        renderNavItem () {
            return this.navTree.map(item => (
                <div class={{
                    'data-type-item': true,
                    'data-type-item-active': this.navActiveId === item.id,
                    'data-type-item-hover': this.hoverActive === item.id,
                    'data-type-item-all': item.id === 'all'
                }}
                    onClick={this.handleNavClick.bind(null, item)}
                    onMouseenter={this.handleNavEnter.bind(null, item)}
                >{item.name}</div>
            ))
        }
    },
    render () {
        return (
            <div class="material-data-type-content" onMousemove={this.handleMove} onMouseleave={this.handleNavLeave}>
                <div class="data-type-content">
                    {this.renderNavItem()}
                </div>
                <div class={{
                    'data-type-wrapper': true,
                    'transtion-hover': true
                }}
                    style={this.wrapperStyle}
                    ref="dataTypeWrapper"
                >
                    <div class="data-item-wrapper">
                        <dataTypeItem ref="dataTypeItem" dataList={this.children} />
                    </div>
                </div>
            </div>
        )
    }
}
