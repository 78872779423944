import DataType from './data-type'
import { pinyin } from 'pinyin-pro'

function isNumber (str) {
    return /[0-9]/.test(str)
}
function isEng (str) {
    return /[a-zA-Z]/.test(str)
}
function isChenese (str) {
    return /[\u4e00-\u9fa5]/.test(str)
}

console.log(pinyin('我的', {
    toneType: 'none'
}))

function sortFunc (a, b) {
    const c = a.length < b.length ? a.length : b.length
    let result = 0
    for (let i = 0; i < c; i++) {
        if (a[i] !== b[i]) {
            if (isNumber(a[i]) && !isNumber(b[i])) {
                result = -1
            }
            if (!isNumber(a[i]) && isNumber(b[i])) {
                result = 1
            }
            if (isNumber(a[i]) && isNumber(b[i])) {
                result = a[i] - b[i]
            }
            if (isEng(a[i]) && !isEng(b[i]) && result === 0) {
                result = -1
            }
            if (!isEng(a[i]) && isEng(b[i]) && result === 0) {
                result = 1
            }
            if (isEng(a[i]) && isEng(b[i] && result === 0)) {
                result = a[i] > b[i] ? 1 : -1
            }
            if (isChenese(a[i]) && !isChenese(b[i]) && result === 0) {
                result = -1
            }
            if (!isChenese(a[i]) && isChenese(b[i]) && result === 0) {
                result = 1
            }
            if (isChenese(a[i]) && isChenese(b[i]) && result === 0) {
                result = sortFunc(pinyin(a[i], { toneType: 'none' }), pinyin(b[i], { toneType: 'none' }))
            }
        }
        if (result !== 0) {
            break
        }
    }
    return result !== 0 ? result : 1
}

const list = ['123', '1234', '234', 'C-中文', 'd-英文', '中文', '英文', '1-123']

// console.log(list.sort((a, b) => sortFunc(a, b)))

export default {
    name: 'DataTypeItem',
    props: {
        dataList: {
            type: Array,
            default: () => ([])
        },
        level: {
            type: Number,
            default: 2
        }
    },
    inject: ['handleNavClick', 'navActiveIdList'],
    data () {
        return {
            hoverActive: null,
            children: [],
            visible: false
        }
    },
    computed: {
        comStyle () {
            return {
                left: this.level === 2 ? '0%' : '100%',
                top: 0
            }
        },
        active () {
            const navActiveIdList = this.navActiveIdList()
            return navActiveIdList[this.level - 1]
        },
        dataListSort () {
            // eslint-disable-next-line vue/no-side-effects-in-computed-properties
            return [...this.dataList].sort((a, b) => {
                return sortFunc(a.name, b.name)
            })
        }
    },
    methods: {
        renderItem () {
            return this.dataListSort.map(item => (
                <div class={
                    {
                        'type-item': true,
                        'type-item-active': this.active === item.id,
                        'type-item-hover': this.hoverActive === item.id
                    }
                }
                    onClick={this.handleNavClick.bind(null, item)}
                    onMouseenter={this.handleNavEnter.bind(null, item)}
                >
                    <span>{item.name}</span>
                    {
                        !!item.list.length && <a-icon type="right" />
                    }
                </div>
            ))
        },
        hide () {
            this.children = []
            this.hoverActive = null
            this.$refs.dataTypeItem && this.$refs.dataTypeItem.hide()
        },
        handleNavEnter (navItem) {
            this.hide()
            this.children = [...navItem.list]
            this.hoverActive = navItem.id
        },
        handleEcho () {
            this.$nextTick(() => {
                const navItem = this.dataList.find(item => item.id === this.active)
                if (navItem) {
                    this.handleNavEnter(navItem)
                    this.$refs.dataTypeItem && this.$refs.dataTypeItem.handleEcho()
                }
            })
        },
        handleScrollTop () {
            this.$nextTick(() => {
                this.$refs.typeBox.scrollTop = 0
                this.$refs.dataTypeItem && this.$refs.dataTypeItem.handleScrollTop()
            })
        }
    },
    render () {
        return (
            <div class="data-type-item-content" style={this.comStyle}>
                <div class="type-item-box" ref="typeBox">
                    {
                        !!this.dataList.length && this.renderItem()
                    }
                </div>
                {
                    (this.level <= 5) && <DataTypeItem ref='dataTypeItem' level={this.level + 1} dataList={this.children} />
                }
            </div>
        )
    }
}
