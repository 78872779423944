<template>
  <div class="msg__shop_wrapper">
    <div class="commom_title">
      <a-row type="flex" justify="space-between">
        <a-col :span="8">
          <a-row>
            <a-col :span="2">
              <div class="commom_line"></div>
            </a-col>
            <a-col :span="6">资料商城</a-col>
          </a-row>
        </a-col>
        <a-col :span="5" style="display: flex; align-items: center; margin-right: 15px">
          <a-input-search
            enter-button="搜索"
            @search="Search"
            v-model="queryParams.title"
            placeholder="请输入资料名称"
            allowClear
            class="search_inp"
          />
        </a-col>
      </a-row>
    </div>
    <div class="content">
      <div class="search-content">
        <dataType v-model="queryParams.categoryId" @change="handleChange" />
      </div>
      <div class="search-active-list">
        <div class="search-label">当前筛选：</div>
        <div class="search-active-content">
          <div v-for="(item, index) in activeList" :key="item.id" class="active-item">
            <div
              :class="{
                'active-item-text': true,
                'active-item-text-active': item.id === queryParams.categoryId,
              }"
              @click="handleClickItem(item, index)"
            >
              {{ item.name }}
            </div>
            <a-icon type="right" style="font-size: 12px" v-if="index < activeList.length - 1" />
          </div>
        </div>
      </div>
      <div class="body-content">
        <div style="height: 730px">
          <v-card-list
            ref="listCard"
            :getDataApi="getDataApi"
            :renderItem="renderItem"
            :colConfig="{
              xs: 8,
              md: 6,
            }"
          >
            <a-empty class="empty" slot="empty" />
          </v-card-list>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  employmentLearnfindPopMaterial,
  employmentLearnlist,
  employmentLearnStyle,
  templateMaterialFindTree
} from '@/api/msg'
import dataType from './data-type.jsx'

export default {
  name: 'Index',
  components: {
    dataType
  },
  data () {
    return {
      val: '1',
      queryParams: {
        categoryId: 'all'
      },
      list: [],
      activeList: [
        {
          id: 'all',
          name: '全部'
        }
      ]
    }
  },
  methods: {
    handleChange (activeList) {
      this.activeList = activeList
      this.Search()
    },
    handleClickItem ({ id }, index) {
      this.queryParams.categoryId = id
      this.activeList.splice(index + 1, this.activeList.length)
      this.Search()
    },
    Search () {
      this.$refs.listCard.pageInit()
    },
    getDetail (item) {
      window.open(`/material/material-details?id=${item.id}`)
    },
    getDataApi (params) {
      return employmentLearnlist({
        ...params,
        ...{
          ...this.queryParams,
          categoryId: this.queryParams.categoryId === 'all' ? '' : this.queryParams.categoryId
        }
      })
    },
    renderItem ({ item, index, data }) {
      return (
        <div class="card-item" onClick={this.getDetail.bind(null, item)}>
          <div class="avatar">
            <a-avatar shape="square" size={150} src={item.thumbImg} />
          </div>
          <div class="title-box">
            <div class="data-type">{item.fileType}</div>
            <div class="data-name text-overflow">{item.title}</div>
          </div>
          <div class="data-size">文件大小：{item.fileSize}</div>
          {/* <div class="data-price">
            <div class="vip-price">￥{ item.memberPrice }</div>
            <div class="price">非会员：￥{ item.price }</div>
          </div> */}
          <div class="data-price">
            {/* <div class="vip-price">价格￥{ item.price / 10 }</div> */}
            <div class="vip-type">会员专享</div>
          </div>
        </div>
      )
    }
  }
}
</script>

<style lang="less">
@import url('../../common/style');
@import url('../materialShop/search/index');
@import url('./index');
</style>
<style lang="less">
.msg__shop_wrapper {
  .content {
    padding: 24px;
    .search-content {
      height: 60px;
      position: relative;
    }
  }
  .search-active-list {
    padding-top: 15px;
    display: flex;
    font-size: 14px;
    align-items: center;
    .search-active-content,
    .active-item {
      display: flex;
      align-items: center;
      .active-item-text {
        padding: 0 5px;
        cursor: pointer;
        &-active,
        &:hover {
          color: rgba(22, 119, 255, 1);
        }
      }
    }
  }
  .body-content {
    padding-top: 20px;
    .ant-col {
      width: 20%;
    }
  }
  .card-item {
    margin: 14px auto;
    width: 90%;
    height: 307px;
    padding: 28px 20px;
    background: #ffffff;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    position: relative;
    transition: all 0.3s;
    -webkit-transition-timing-function: ease-out;
    transition-timing-function: ease-out;
    cursor: pointer;
    top: 0px;
    &:hover {
      top: -10px;
      box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.15);
      background: #ffffff;
    }
    &:nth-child(5n) {
      margin-right: 0px;
    }
    .avatar {
      width: 100%;
      display: flex;
      justify-content: center;
    }
    .title-box,
    .data-size,
    .data-price {
      margin: 10px 0px;
    }
    .title-box {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      .data-type {
        font-weight: 300;
        font-size: 12px;
        color: #1677ff;
        background: rgba(22, 119, 255, 0.1);
        border-radius: 2px;
        padding: 2px 6px;
      }
      .data-name {
        font-weight: 300;
        font-size: 14px;
        color: #333333;
        margin-left: 10px;
      }
    }
    .data-size {
      font-weight: 300;
      font-size: 12px;
      color: #666666;
    }
    .data-price {
      display: flex;
      align-items: center;
      .vip-price {
        font-weight: 400;
        font-size: 12px;
        color: #e21f1f;
        text-decoration: line-through;
      }
      .vip-type{
        padding: 1px 5px;
        background: #1677ff;
        border-radius: 2px;
        color: #ffffff;
        font-size: 12px;
      }
      .price {
        font-weight: 300;
        font-size: 12px;
        color: #999999;
        margin-left: 10px;
      }
    }
  }
}
.msgShop_title .ant-select-selection--single {
  height: 38px;
  background: #1677ff;
  border: 1px solid #1677ff;
}

.msgShop_title .ant-select-selection__placeholder {
  color: #fff;
  font-size: 16px;
}

.msgShop_title .ant-select-arrow {
  color: #fff;
}

.msgShop_title .ant-select-selection-selected-value {
  color: #fff;
  font-size: 16px;
}
</style>
